import styled from 'styled-components';
import {Button} from '../Button';
import Typography from '../../constants/typography';

interface BackgroundProps {
  inverse?: boolean;
}

export const Background = styled.section<BackgroundProps>`
  background-color: ${(p) => (p.inverse ? '#363636' : '#222')};
  color: #fff;
  padding: 5em 0;
`;

export const Product = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 5%;
  align-items: center;

  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`;

export const Subtitle = styled.h3`
  font-size: ${Typography.subtitle.desktop.fontSize};
  margin: 0.5em 0;

  @media (max-width: 600px) {
    margin-top: 2em;
  }
`;

export const Title = styled.h2`
  font-size: ${Typography.title.desktop.fontSize};
  line-height: ${Typography.title.desktop.lineHeight};
`;

export const LearnButton = styled(Button)`
  margin-top: 1em;

  @media (max-width: 600px) {
    margin-top: 2em;
  }
`;
