import React from 'react';
import {graphql, PageProps} from 'gatsby';
import {Redirect} from '@reach/router';
import {Layout} from '../components/Layout';
import PageWidth from '../components/PageWidth';
import HeaderImage from '../components/HeaderImage';
import SEO from '../components/SEO';
import {CrossProduct} from '../components/CrossProduct';
import {PageWrapper, RichText} from '../styles/page';
import renderRichText from '../utils/rich-text-renderer';
import {getImageFromData, getPageData} from '../utils';
import {SaleQuery} from './__generated__/SaleQuery';

const Sale = (props: PageProps<SaleQuery>) => {
  const data = getPageData(props.data);
  const headerImage = getImageFromData(data, 'headerImage');

  if (data.enabled === false) {
    // Note: No Throw is being used to surpress a bug in Gatsby
    // can read some chat about this bug here:
    // https://github.com/gatsbyjs/gatsby/issues/8838
    return <Redirect to="/" noThrow />;
  }

  return (
    <Layout>
      <SEO title={data.pageTitle} />
      <HeaderImage src={headerImage} />
      <PageWrapper>
        <PageWidth narrow>
          <RichText>{renderRichText(data.pageContent)}</RichText>
        </PageWidth>
      </PageWrapper>
      <CrossProduct {...data.crossProduct1} />
      <CrossProduct {...data.crossProduct2} inverse />
      <CrossProduct {...data.crossProduct3} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SaleQuery {
    en: allContentfulAmpifyWebSale(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          enabled
          metaKeywords
          metaDescription
          pageTitle
          pageContent {
            raw
          }
          headerImage {
            ...AmpifyHeaderImage
          }
          crossProduct1 {
            introTitle
            introText
            introSubtitle
            introImage {
              title
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            relativeUrl
          }
          crossProduct2 {
            introTitle
            introSubtitle
            introImage {
              title
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            relativeUrl
          }
          crossProduct3 {
            introTitle
            introSubtitle
            introImage {
              title
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            relativeUrl
          }
        }
      }
    }
  }
`;

export default Sale;
