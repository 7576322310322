import React, {FunctionComponent} from 'react';
import Img, {FluidObject} from 'gatsby-image';
import PageWidth from '../PageWidth';
import {ButtonVariant} from '../Button';
import {
  Background,
  Product,
  Subtitle,
  Title,
  LearnButton,
} from './CrossProduct.styles';

interface CrossProductProps {
  introSubtitle: string;
  introTitle: string;
  introImage: {
    fluid: FluidObject;
  };
  relativeUrl: string;
  grey: boolean;
  inverse: boolean;
}

export const CrossProduct: FunctionComponent<CrossProductProps> = ({
  introSubtitle,
  introTitle,
  introImage,
  relativeUrl,
  grey,
  inverse,
}) => (
  <Background inverse={inverse}>
    <PageWidth>
      <Product>
        <div>
          <Img
            style={{
              width: '100%',
            }}
            fluid={introImage.fluid}
          />
        </div>
        <div>
          <Subtitle>{introSubtitle}</Subtitle>
          <Title>{introTitle}</Title>
          <a href={relativeUrl} title={introSubtitle}>
            <LearnButton variant={ButtonVariant.BLACK_OUTLINE_WHITE_FILL}>
              Learn more
            </LearnButton>
          </a>
        </div>
      </Product>
    </PageWidth>
  </Background>
);
